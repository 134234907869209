<template>
    <v-app id="title">
        <div class="transparent-layer"></div>
        <h1 class="texts">Training Placement Opportunity</h1>
        <v-container>
            <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
                <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
                <v-btn dark text @click="snackbar = false">Close</v-btn>
            </v-snackbar>
            <v-row  align="center" justify="center">
                <v-col cols="12" sm="12">
                    <v-card class="elevation-10 mt-6">
                        <v-row dense>
                             <v-col cols="12" sm="6">
                                <v-card-text class="mt-8" dense>
                                    <v-carousel height="auto" cycle hide-delimiter-background hide-delimiters-on-mobile :prev-icon="false" :next-icon="false" hide-delimiters>
                              <v-carousel-item v-for="(item, i) in logo" :key="i">
                              <center>
                              <v-img :src="item.logo_url" width="250"></v-img>
                              </center>
                              </v-carousel-item>
                           </v-carousel><br>
                                    <h4 class="custom-text-color">Alumini-Signup</h4>
                                        <v-row class="mx-12 first-row" justify="center">
                                            <v-col col="12" sm="6" md="8">
                                                <div>
                                                    <v-text-field :disabled="disable_email" v-model="email" type="email"
                                                        outlined dense color="black" >

                                                        <template #label>Email<spane
                                                                style="color:red ; font-size:20px;">*</spane></template>
                                                    </v-text-field>
                                                </div>
                                            </v-col>
                                            <v-col cols="12" sm="2" md="4">
                                                
                                                    <center>
                                                        <v-btn class="logi" dark block @click="Sendotp"
                                                            :disabled="disable_send_otp_button"
                                                            :loading="send_otp_loading">Send Otp</v-btn>
                                                    </center>
                                                
                                            </v-col>
                                        </v-row>
                                         <v-row v-if="is_otp_send"  class="mx-12 first-row" justify="center">
                                        <v-col cols="12" sm="6" md="8">
                                           
                                                <v-text-field :disabled="disable_otp" outlined dense v-model="email_otp"
                                                    color="black">
                                                    <template #label> Email Otp<span
                                                            style="color: red; font-size: 20px;">*</span> </template>
                                                </v-text-field>
                                            
                                        </v-col>
                                        <v-col cols="12" sm="2" md="4">
                                           
                                                <center>
                                                    <v-btn class="green" dark block @click="verifyOTP"
                                                        :loading="send_otp_loading"
                                                        :disabled="disable_verify_otp_button">Verify Otp
                                                    </v-btn>
                                                </center>
                                            
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="is_email_verified" justify="center" class="first-row">
                                        
                                                <v-col col="12" sm="4">
                                                    <v-text-field 
                                                        v-model="first_name"
                                                        outlined dense 
                                                        color="black"
                                                        >
                                                        <template #label> First Name<span style="color: red; font-size: 20px;">*</span> </template></v-text-field>
                                                </v-col>
                                                <v-col col="12" sm="4">
                                                    <v-text-field 
                                                        v-model="middle_name"
                                                        outlined dense 
                                                        color="black"
                                                        >
                                                        <template #label>
                                                            Middle Name
                                                        </template></v-text-field>
                                                </v-col>
                                                <v-col col="12" sm="4">
                                                    <v-text-field 
                                                        v-model="last_name"
                                                        outlined dense
                                                        color="black"
                                                        >
                                                        <template #label> Last Name<span style="color: red; font-size: 20px;">*</span> </template></v-text-field>
                                                </v-col>                                     
                                    </v-row>
                                    <v-row v-if="is_email_verified" justify="center" class="first-row">
                                        <v-col col="12" sm="12">
                                            <v-text-field 
                                            v-model="mobile_no"
                                            outlined dense 
                                            color="black"
                                            >
                                            <template #label> Mobile No<span style="color: red; font-size: 20px;">*</span> </template></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row  v-if="is_email_verified" justify="center" class="first-row">
                                        <v-col col="12" sm="12">
                                            <v-text-field
                                            v-model="password"
                                             @click:append-inner="update_password_icon('password')"
                                             :append-inner-icon="password_icon"
                                             :type="password_type"
                                             outlined dense
                                             color="black"
                                             
                                             autocomplete="false" 
                                             hint="Password Length Must Be Greather than 8 char"  >
                                             <template #label> create Password<span style="color: red; font-size: 20px;">*</span> </template>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="is_email_verified" justify="center" class="first-row">
                                        <v-col col="12" sm="12">
                                            <v-text-field
                                            @click:append-inner="update_password_icon('confirm_password')"
                                            :append-inner-icon="confirm_password_icon"
                                            :type="confirm_password_type"
                                            outlined dense
                                            color="black"
                                            v-model="confirm_password"
                                            hint="Password & Confirm Password Should Be Same"
                                        >
                                            <template #label> Confirm Password<span style="color: red; font-size: 20px;">*</span> </template>
                                        </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="is_email_verified" justify="center" class="first-row">
                                        <v-col col="12" sm="12">
                                            <v-checkbox
                                            v-model="is_agree"
                                            @change="enable_register"
                                            label="I have noted down, mobile number and Email Id for future communication.My Email ID will be used as username in this portal "
                                            color="green"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="is_email_verified" justify="center" >
                                        <v-col col="12" sm="12">
                                            <v-btn 
                                            class="logi" 
                                            dark block tile
                                            @click="register" 
                                            :loading="register_loading" 
                                            :disabled="register_button_disable" 
                                            >Resigter</v-btn>
                                        </v-col>
                                    </v-row> 
                                </v-card-text>
                            </v-col> 
                            <v-col cols="12" sm="6" dense class="custom-text-colors">
                                <div style="text-align: center; padding: 60px;">
                                    <img src="../assets/logowhite.png" alt="logo" class="log">
                                    <div style="text-align: center; ">
                                        <v-card-text class="white--text">
                                            <h3 class="text-center">Already Alumini Signed up</h3>
                                            <h3 class="text-center">Go to Login Signup</h3>
                                        </v-card-text>
                                        <div class="text-center">
                                            <router-link to="/" style="text-decoration: none;" title="Back">
                                                <v-icon large color="grey darken">mdi-chevron-left-circle</v-icon>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    
    </v-app>
</template>
<script>
import axios from "axios";
import crypto from "../service/crypto";
export default {
   
    data() {
        return {
            snackbar_msg: "",
            color: "",
            snackbar: false,
            first_name: "",
            last_name: "",
            middle_name: "",
            logo: "",
            email: "",
            email_otp: "",
            mobile_no: "",
            is_email_verified: false,
            disable_email: false,
            is_otp_send: false,
            send_otp_loading: false,
            disable_otp: false,
            verify_email_loading: false,
            is_retired_army_personnel: false,
            disable_send_otp_button: false,
            disable_verify_otp_button: false,
            password: "",
            confirm_password: "",
            password_type: "password",
            confirm_password_type: "password",
            password_icon: "mdi mdi-eye",
            confirm_password_icon: "mdi mdi-eye",
            register_loading: false,
            register_button_disable: true,
            is_register_mah_cet: false,
            is_agree: false,
        }
    },
    mounted() {
            this.init();
            this.getLogo();
    },
    methods: {
        getLogo() {
      var url = window.location.href;
      const data = {
        url: url,
      };
      axios.post("Login/getlogo", data).then((res) => {
        // console.log(res);
        if (res.data.status == "200" && res.data.msg == "success") {
          // console.log(res.data);
          // console.log(res.data.o[0].learner_android_app_link);
          this.logo = res.data.logo;
          
        }
      });
    },//end of function
        showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            register(){
                if (!/^[^@]+@\w+(\.\w+)+\w$/.test(this.email)) {
                    this.showSnackbar("red", "Please Enter Valid Email.");
                    return;
                }
                if (!this.email) {
                    this.showSnackbar("red", "Please Enter Email.");
                    return;
                }
                if (!this.is_email_verified) {
                    this.showSnackbar("red", "PLease Verify Email First.");
                    return;
                }
                if (!this.first_name) {
                    this.showSnackbar("red", "PLease Enter First Name.");
                    return;
                }
                if (!this.last_name) {
                    this.showSnackbar("red", "PLease Enter Last Name.");
                    return;
                }
                if (!this.mobile_no) {
                    this.showSnackbar("red", "PLease Enter Mobile No.");
                    return;
                }
                if (!this.password) {
                    this.showSnackbar("red", "PLease Enter Password.");
                    return;
                }
                if (!this.confirm_password) {
                    this.showSnackbar("red", "PLease Enter Confirm Password.");
                    return;
                }
                if (this.confirm_password != this.password) {
                    this.showSnackbar("red", "Password And Confirm Password Should Be Same.");
                    return;
                }
                if (this.password.length < 8) {
                    this.showSnackbar("red", "Password Should Be Greater Than 8 Character.");
                    return;
                }
                if (this.confirm_password.length < 8) {
                    this.showSnackbar("red", "Confirm  Password Should Be Greater Than 8 Character.");
                    return;
                }
                
                this.register_loading = true;
                var params ={
                    email: this.email,
                    first_name: this.first_name,
                    last_name: this.last_name,
                    middle_name: this.middle_name,
                    mobile_no:this.mobile_no,
                    parent_name: this.parent_name,
                    parent_rank: this.parent_rank,
                    mahcet_application_no: this.mah_cet_application_no,
                    password: this.password,
                    confirm_password: this.confirm_password,
                }
                const enc = crypto.encrypt(params);
                axios
                    .post("Alumini/register", {
                        params: enc,
                    })
                    .then((res) => {
                        this.status = res.data.status;
                        if (this.status == "200") {
                            this.$router.push("/");
                            this.showSnackbar("green", "Succesfully Registered.");
                            this.register_loading = false;
                            this.is_email_verified = false;
                        } else {
                            this.showSnackbar("red", res.data.msg);
                            this.register_loading = false;
                        }
                    });

                return;
            },
            init() {},
            
            enable_register(){
                if(this.is_agree){
                    this.register_button_disable = false;
                }else{
                    this.register_button_disable = true;
                }
            },
            Sendotp(){
                this.send_otp_loading = true;
                if (!this.email) {
                    this.showSnackbar("red", "Please Enter Email.");
                    this.disable_email = false;
                    this.send_otp_loading = false;
                    return;
                }
                if (!/^[^@]+@\w+(\.\w+)+\w$/.test(this.email)) {
                    this.showSnackbar("red", "Please Enter Valid Email.");
                    this.disable_email = false;
                    this.send_otp_loading = false;
                    return;
                }
                var params = {
                    email: this.email,
                };
                const enc = crypto.encrypt(params);
                axios
                    .post("Alumini/sendOTP", {
                        params: enc,
                    })
                    .then((res) => {
                        this.status = res.data.status;
                        if (this.status == "200") {
                            this.showSnackbar("green", res.data.msg);
                            this.disable_email = true;
                            this.send_otp_loading = false;
                            this.is_otp_send = true;
                            this.disable_send_otp_button = true;
                        } else {
                            this.showSnackbar("red", res.data.msg);
                            this.disable_email = false;
                            this.send_otp_loading = false;
                        }
                    });
            },
            verifyOTP() {
                if (!this.email) {
                    this.showSnackbar("red", "Please Enter Email.");
                    this.disable_email = false;
                    this.send_otp_loading = false;
                    return;
                }
                if (!/^[^@]+@\w+(\.\w+)+\w$/.test(this.email)) {
                    this.showSnackbar("red", "Please Enter Valid Email.");
                    this.disable_email = false;
                    this.send_otp_loading = false;
                    return;
                }
                this.disable_email = true;
                this.disable_otp = true;
                this.verify_email_loading = true;
                var params = {
                    email: this.email,
                    otp: this.email_otp,
                };
                const enc = crypto.encrypt(params);
                axios
                    .post("Alumini/verifyOTP", {
                        params: enc,
                    })
                    .then((res) => {
                        this.status = res.data.status;
                        if (this.status == "200") {
                            this.showSnackbar("green", res.data.msg);
                            this.verify_email_loading = false;
                            this.disable_otp = true;
                            this.disable_verify_otp_button = true;
                            this.is_email_verified = true;
                        } else {
                            this.showSnackbar("red", res.data.msg);
                            this.send_otp_loading = false;
                            this.disable_otp = false;
                        }
                    });
            },

    },

}
</script>
<style>
.v-application .rounded-bl-xl {
    border-bottom-left-radius: 250px !important;
}

.v-application .rounded-br-xl {
    border-bottom-right-radius: 250px !important;
}

#title {
    background-image: url("../assets/lapt.jpg");
    background-size: cover;
    background: cover;
    background-repeat: no-repeat;
    position: relative;
     
      
}
.transparent-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(40, 64, 82, 0.466);
    
 }
 .texts {
    text-align: center;
    color: white;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin-top: 50px;
    z-index: 3; 
    position: relative; 
}
.custom-text-color{
    color: darkblue;
    font-size: 22px;
    text-align: center;
 }
.logi{
   
    background: linear-gradient(to top, #09203f 0%, #537895 100%);
  }

.log {
    margin-bottom: 40px;
    max-width: 210px;
}
.custom-text-colors{
    background: linear-gradient(112.6deg, rgb(56, 24, 40) -10.7%, rgb(23, 148, 185) 100.2%);
 }
 .first-row {
    margin-bottom: -24px; 
}
 
</style>